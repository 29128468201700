// signatureReducer.ts

import { initialState } from './signatureTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TargetPayShopperSignatureResponse, ValidatedSignatureToken } from '../../types/types';

/**
 * Redux slice for managing the signature state.
 * Handles actions related to signature token, terminal, processing state, error messages, and response data.
 */
const signatureSlice = createSlice({
  name: 'signature',
  initialState,
  reducers: {
    /**
     * Sets the error message in the state.
     * @param state - The current state of the signature slice.
     * @param action - The error message to be set in the state.
     */
    setErrorMessage(state, action: PayloadAction<string>) {
      state.errorMessage = action.payload;
    },

    /**
     * Sets the terminal ID in the state.
     * @param state - The current state of the signature slice.
     * @param action - The terminal ID to be set in the state.
     */
    setTerminalId(state, action: PayloadAction<string>) {
      state.terminalId = action.payload;
    },

    /**
     * Sets the list of terminals in the state and marks data as fetched.
     * @param state - The current state of the signature slice.
     * @param action - The list of terminals to be set in the state.
     */
    setTerminalList(state, action: PayloadAction<any[]>) {
      state.terminalList = action.payload;
      state.dataFetched = true;
    },

    /**
     * Sets whether the signature fetch operation is completed.
     * @param state - The current state of the signature slice.
     * @param action - Boolean value indicating whether the signature fetch is completed.
     */
    setSignatureFetched(state, action: PayloadAction<boolean>) {
      state.signatureFetched = action.payload;
    },

    /**
     * Sets the processing state for the signature request.
     * @param state - The current state of the signature slice.
     * @param action - Boolean value indicating whether a process is ongoing.
     */
    setIsProcessing(state, action: PayloadAction<boolean>) {
      state.isProcessing = action.payload;
    },

    /**
     * Sets the signature response in the state.
     * @param state - The current state of the signature slice.
     * @param action - The signature response to be set in the state.
     */
    setSignatureResponse(state, action: PayloadAction<TargetPayShopperSignatureResponse>) {
      state.signatureResponse = action.payload;
      state.signatureFetched = true;
    },

    /**
     * Sets the validated signature data in the state.
     * @param state - The current state of the signature slice.
     * @param action - The validated signature data to be set in the state.
     */
    setValidatedSignatureData(state, action: PayloadAction<ValidatedSignatureToken>) {
      state.validatedSignatureData = action.payload;
    },

    /**
     * Sets the signature token in the state.
     * @param state - The current state of the signature slice.
     * @param action - The signature token to be set in the state.
     */
    setSignatureToken(state, action: PayloadAction<string>) {
      state.signatureToken = action.payload;
    },

        /**
     * Sets the data fetched in the state.
     * @param state - The current state of the data fetched bit.
     * @param action - The data fetched bit to be set in the state.
     */
    setDataFetched(state, action: PayloadAction<boolean>) {
      state.dataFetched = action.payload;
    },

            /**
     * Sets the data fetched in the state.
     * @param state - The current state of the emergepay environment.
     * @param action - The emergepay environment to be set in the state.
     */
    setEmergepayIsSandBox(state, action: PayloadAction<number>) {
      state.EmergepayIsSandBox = action.payload;
    },

    /**
     * Resets the entire signature slice state to its initial values.
     * @param state - The current state of the signature slice.
     * @returns The initial state of the signature slice.
     */
    resetState(state) {
      return initialState;
    }
  }
});

// Export actions generated by createSlice
export const {
  setErrorMessage,
  setTerminalId,
  setTerminalList,
  setSignatureFetched,
  setIsProcessing,
  setSignatureResponse,
  setValidatedSignatureData,
  setSignatureToken,
  setDataFetched,
  setEmergepayIsSandBox,
  resetState
} = signatureSlice.actions;

// Export the reducer to be used in the store
export default signatureSlice.reducer;
