import { forwardRef, useImperativeHandle } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Drawer,
  IconButton,
  Stack,
} from "@mui/material";
import * as React from "react";
import TransactionAuditTrailTimeline from "./transaction.audittrail.timeline";
import { Close, Info } from "@mui/icons-material";
import {
  GetDealerPlatformFeeOption,
  GetDealerHostUrl,
  TargetPayApi,
} from "../../utils/UtilTarget";
import "./transaction.style.css";
import AuditTrailTimelineHelp from "./transaction.audittrail.help";
import { convertToUSD, isEmpty, Callback } from "../../utils/Util";
import "../../utils/string.extension";
import ProgressLoader from "../../common/components/progress.loader";
import PaymentStatusColumn from "../../common/components/paymentstatus.column";
import { PaymentStatus } from "../../types/enums";

const TransactionAuditTrail = forwardRef((props, ref) => {
  type Anchor = "top" | "left" | "bottom" | "right";
  const anchor: Anchor = "right";
  const [timelines, setTimelines] = React.useState<any>([]);
  const [isFetching, setIsFetching] = React.useState(true);
  const [showSummary, setShowSummary] = React.useState(true);
  const [transaction, setTransaction] = React.useState<any>({});
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  // The component instance will be extended with whatever you return from the callback passed
  // as the second argument
  useImperativeHandle(ref, () => ({
    openDrawer(transaction) {
      setIsFetching(true);
      var reqAmount =
        (transaction.RequestedAmount ?? 0) ||
        (transaction.transactionAmount ?? 0);
      var platformFee =
        (transaction.PlatformFee ?? 0) || (transaction.platformFee ?? 0);
      transaction.TotalAmount = reqAmount + platformFee;
      setTransaction(transaction);
      getTimeLineData(transaction.PaymentId || transaction.uniqueTransId);
      toggleDrawer(anchor, true);
    },
  }));

  async function getTimeLineData(paymentId) {
    var resp = await TargetPayApi(
      `AuditTrail/${paymentId}`,
      undefined,
      "Get",
      "Transaction"
    );
    setTimelines(resp);
    setIsFetching(false);
  }

  const toggleDrawer = (anchor: Anchor, open: boolean) => {
    setState({ ...state, [anchor]: open });
  };

  const closeDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      toggleDrawer(anchor, open);
    };

  const emails = ["username@gmail.com", "user02@gmail.com"];
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(emails[1]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value: string) => {
    setOpen(false);
    setSelectedValue(value);
  };

  const handleOnOrderClick = (orderId: string) => {
    Callback(JSON.stringify({ event: "onOrderClick", orderId: orderId }), GetDealerHostUrl());
  };

  return (
    <Drawer
      anchor={anchor}
      open={state[anchor]}
      onClose={closeDrawer(anchor, false)}
    >
      <Box className="audittrail" style={{ height: "100%" }}>
        <Stack
          direction="row"
          alignItems="center"
          spacing={15}
          className="header"
        >
          <h4>Transaction Timeline</h4>
          <Close
            onClick={closeDrawer(anchor, false)}
            sx={{ fontSize: "20px", color: "#c5c6c7", cursor: "pointer" }}
          ></Close>
        </Stack>
        <ProgressLoader isFetching={isFetching}></ProgressLoader>
        {!isFetching && (
          <Stack direction="column" className="content">
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Checkbox
                size="small"
                style={{ display: "none" }}
                checked={showSummary}
                onChange={(e) => {
                  setShowSummary(e.currentTarget.checked);
                }}
              />
              <label style={{ fontSize: "12px" }}>Learn about it</label>
              <IconButton onClick={handleClickOpen}>
                <Info style={{ fontSize: "18px" }}></Info>
              </IconButton>
            </Stack>
            <Stack direction="column" className="transaction-info">
              <div>
                Order#:{" "}
                <label
                  onDoubleClick={(e) =>
                    handleOnOrderClick(
                      transaction.InvoiceId || transaction.invoiceId
                    )
                  }
                >
                  {transaction.InvoiceId || transaction.invoiceId}
                </label>
              </div>
              <div>
                Reference:{" "}
                <label>
                  {transaction.PaymentId || transaction.uniqueTransId}
                </label>
              </div>
              <div>
                Customer:{" "}
                <label>
                  {transaction.CustomerName || transaction.customerName}
                </label>
              </div>
              <div>
                Amount:{" "}
                <label>
                  {convertToUSD(
                    transaction.RequestedAmount || transaction.transactionAmount
                  )}
                </label>
              </div>
              {GetDealerPlatformFeeOption() && (
                <>
                  <div>
                    Platform fee:{" "}
                    <label>
                      {convertToUSD(
                        transaction.PlatformFee || transaction.platformFee
                      )}
                    </label>
                  </div>
                  <div>
                    Total:{" "}
                    <label>{convertToUSD(transaction.TotalAmount)}</label>
                  </div>
                </>
              )}
              <div>
                Status:{" "}
                <PaymentStatusColumn
                  isRefunded={transaction.isRefunded || transaction.IsRefunded}
                  isCancelled={
                    transaction.isCancelled || transaction.IsCancelled
                  }
                  status={
                    transaction.TotalAmount === 0
                      && (transaction.status == PaymentStatus.Success || transaction.Status == PaymentStatus.Success)
                      ? PaymentStatus.CardSaved 
                      : transaction.status || transaction.Status
                  }
                  handleClick={() => {}}
                  refundInitiated={
                    transaction.refundInitiated || transaction.RefundInitiated
                  }
                  renderAsColumn={false}
                ></PaymentStatusColumn>
              </div>
              <div>
                Transaction Date:{" "}
                <label>
                  {isEmpty(transaction.DateProcessed)
                    ? !isEmpty(transaction.DateCreated)
                      ? transaction.DateCreated.toUSDateFormat()
                      : transaction.transactionDate.toUSDateFormat()
                    : transaction.DateProcessed.toUSDateFormat()}
                </label>
              </div>
            </Stack>
            <br />
            <TransactionAuditTrailTimeline
              timelines={timelines}
              showSummary={showSummary}
            ></TransactionAuditTrailTimeline>
          </Stack>
        )}
      </Box>
      <AuditTrailTimelineHelp
        timelines={timelines}
        selectedValue={selectedValue}
        open={open}
        onClose={handleClose}
      ></AuditTrailTimelineHelp>
    </Drawer>
  );
});

export default TransactionAuditTrail;
