// signatureTypes.ts

import { TargetPayShopperSignatureResponse, ValidatedSignatureToken } from "../../types/types";

export interface SignatureState {
    terminalId: string;
    terminalList: any[];
    dataFetched: boolean;
    signatureFetched: boolean;
    dealerId?: number;
    errorMessage: string;
    isProcessing: boolean;
    signatureResponse: TargetPayShopperSignatureResponse
    validatedSignatureData: ValidatedSignatureToken;
  signatureToken: string;
  EmergepayIsSandBox: number;
}

// signatureInitialState.ts
export const initialState: SignatureState = {
  terminalId: "0",
  terminalList: [],
  dataFetched: false,
  signatureFetched: false,
  dealerId: undefined,
  errorMessage: "",
  isProcessing: false,
  signatureResponse: {
    dealerId: undefined,
    isApproved: false,
    additionalInfo: '',
    isNonPaymentType: true,
    status: '',
    CustomerID: '',
    transactionResponse: {
      cashier: '',
      externalTransactionId: '',
      resultMessage: '',
      resultStatus: '',
      signature: '',
      transactionReference: '',
      transactionType:''
    }
  },
  validatedSignatureData: {
    dealerId: undefined,
    customerID: '',
    customerName: '',
    deviceName: '',
    machineName: '',
    userId: undefined,
    transactionReference: '',
    cashierID: '',
    externalTransId:'',
    dealerInfo: '',
    hostUrl: ''
  },
  signatureToken: '',
  EmergepayIsSandBox: -1
};
  