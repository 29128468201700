import { RootState } from "../app/store";
import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { GetRefreshToken } from "../utils/UtilTarget";
import { isEmpty, setAccessTokenInQueryParams } from "../utils/Util";
import { resetState } from "../features/nonPaymentSignature/signatureSlice";

export const authFetchBaseQuery = (baseUrl: string) => {
  return fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      // Add the authorization token to the headers
      const accessToken = (getState() as RootState).auth.access_token;
      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
      }
      return headers;
    },
  });
};

//base query operational with local storage.
const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BASE_URL,
  prepareHeaders: (headers) => {
    headers.set("Content-Type", "application/json");
    headers.set("Accept", "application/json");
    let apiKey = sessionStorage.getItem("nonce");
    const accessToken = localStorage.getItem("access_token");
    if (!isEmpty(apiKey)) headers.set("X-API-KEY", apiKey);
    else headers.set("Authorization", `Bearer ${accessToken}`);
    return headers;
  },
});

// Custom base query to handle specific status codes
export const baseQueryWithRefreshTokenHandler = async (
  args,
  api,
  extraOptions
) => {
  let result = await baseQuery(args, api, extraOptions);
  // Handle specific status codes
  if (result.error) {
    if (result.error.status === 401) {
      // Handle 401 Unauthorized
      const refresh_token = localStorage.getItem("refresh_token");
      // Call the refresh token function
      const success: boolean = await GetRefreshToken(refresh_token); //get the new token using refresh token
      if (success) {
        // If the refresh was successful, retry the original request
        const refreshedAccessToken = localStorage.getItem("access_token"); // Update local storage with the new token
        setAccessTokenInQueryParams(refreshedAccessToken);
        // Retry the original request with the updated headers
        result = await baseQuery(args, api, extraOptions);
      } else {
        api.dispatch(resetState());
        window.location.href = "/login";
      }
    }
  }

  return result;
};
